.navigationWrapper {
  display: grid;
  grid-template-columns: 70% 30%;
  position: fixed;
  width: 100%;
  height: 99px;
  background-color: #fff;
  opacity: 0.9;
  z-index: 999999999;
}

.navigationList__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.naviagtionList__element {
  color: #23242a;
  list-style: none;
  font-family: "Gelasio", serif;
  text-align: center;
  justify-self: stretch;
  font-weight: 500;
  font-size: 18px;
  display: grid;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  cursor: pointer;
  top: 48px;
  margin-right: 50px;
}

.navigationList__wrapper {
  text-decoration: none;
  justify-self: center;
  align-self: center;
}

// Sprawdzic na widokach czy da się usunąc padding i margin 106px
.imgNavbar {
  padding-top: 20px;
  margin-left: 106px;
  width: 224px;
  height: 83px;
  padding-left: 106px;
  display: grid;
  justify-content: start;
}

.imgNavigations {
  display: grid;
  justify-content: start;
}
.hamburgerMenu {
  visibility: hidden;
}
.navbarList {
  list-style: none;
}
@media (max-width: 1024px) {
  .navigationWrapper {
    display: grid;
    grid-template-columns: 68% 34%;
  }
  .imgNavbar {
    margin-left: 4px;
  }
}

@media (max-width: 768px) {
  .navigationList__wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: #a6263e;
    top: 0;
    left: -110%;
    transition: all 0.5s;
    z-index: 2;
  }
  .navigationWrapper {
    background-color: #fff;
    z-index: 2;
    opacity: 0.9;
  }
  .hamburgerMenu {
    visibility: visible;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 999999999999;
    top: 33%;
    left: 11%;
    cursor: pointer;
  }
  .imgNavbar {
    margin-left: 419px;
  }
  .navigationList__wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: #a6263e;
    top: 0;
    left: -110%;
    transition: all 0.5s;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    height: 734px;
  }
  .naviagtionList__element {
    color: #fff;
    font-size: 50px;
    justify-content: center;
    font-family: "Gelasio", serif;
    font-weight: bold;
  }
  .navbarList {
    padding-top: 10px;
  }
}
@media (max-width: 625px) {
  .navigationWrapper {
    background-color: #fff;
    z-index: 2;
    opacity: 0.9;
  }
  .navigationList__wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: #a6263e;
    top: 0;
    left: -110%;
    transition: all 0.5s;
  }
  .hamburgerMenu {
    visibility: visible;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 999999999999;
    top: 33%;
    left: 4%;
    cursor: pointer;
  }
  .navigationList__wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: #a6263e;
    top: 0;
    left: -110%;
    transition: all 0.5s;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    height: 734px;
  }

  .naviagtionList__element {
    color: #fff;
    font-size: 50px;
    justify-content: center;
    font-family: "Gelasio", serif;
    font-weight: bold;
  }
  .navbarList {
    padding-top: 10px;
  }
  .imgNavbar {
    margin-left: 365px;
  }
}

@media (max-width: 560px) {
  .navigationWrapper {
    background-color: #fff;
    z-index: 2;
    opacity: 0.9;
  }
  .navigationList__wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: #a6263e;
    top: 0;
    left: -110%;
    transition: all 0.5s;
  }
  .hamburgerMenu {
    visibility: visible;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 999999999999;
    top: 33%;
    left: 4%;
    cursor: pointer;
  }
  .imgNavbar {
    margin-left: 260px;
  }
  .navigationList__wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: #a6263e;
    top: 0;
    left: -110%;
    transition: all 0.5s;
  }
  .hamburgerMenu {
    visibility: visible;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 999999999999;
    top: 33%;
    left: 4%;
    cursor: pointer;
  }
  .navigationList__wrapper {
    left: 0px;
    background-color: #a6263e;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    height: 734px;
  }
  .naviagtionList__element {
    color: #fff;
    font-size: 50px;
    justify-content: center;
    font-family: "Gelasio", serif;
    font-weight: bold;
  }
  .navbarList {
    padding-top: 10px;
  }
}

@media (max-width: 540px) {
  .navigationWrapper {
    background-color: #fff;
    z-index: 2;
    opacity: 0.9;
  }
  .navigationList__wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: #a6263e;
    top: 0;
    left: -110%;
    transition: all 0.5s;
  }
  .hamburgerMenu {
    visibility: visible;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 999999999999;
    top: 33%;
    left: 4%;
    cursor: pointer;
  }
  .imgNavbar {
    margin-left: 260px;
  }

  .hamburgerMenu {
    visibility: visible;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 999999999999;
    top: 33%;
    left: 4%;
    cursor: pointer;
  }
  .navigationList__wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    height: 734px;
  }
  .naviagtionList__element {
    color: #fff;
    font-size: 50px;
    justify-content: center;
    font-family: "Gelasio", serif;
    font-weight: bold;
  }
  .navbarList {
    padding-top: 10px;
  }
}

@media (max-width: 525px) {
  .navigationWrapper {
    background-color: #fff;
    z-index: 2;
    opacity: 0.9;
  }
  .navigationList__wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: #a6263e;
    top: 0;
    left: -110%;
    transition: all 0.5s;
  }
  .hamburgerMenu {
    visibility: visible;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 999999999999;
    top: 33%;
    left: 4%;
    cursor: pointer;
  }
  .imgNavbar {
    margin-left: 260px;
  }
  .navigationList__wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: #a6263e;
    top: 0;
    left: -110%;
    transition: all 0.5s;
  }
  .hamburgerMenu {
    visibility: visible;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 999999999999;
    top: 33%;
    left: 4%;
    cursor: pointer;
  }
  .navigationList__wrapper {
    left: 0px;
    background-color: #a6263e;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    height: 734px;
  }
  .naviagtionList__element {
    color: #fff;
    font-size: 50px;
    justify-content: center;
    font-family: "Gelasio", serif;
    font-weight: bold;
  }
  .navbarList {
    padding-top: 10px;
  }
}

@media (max-width: 424px) {
  .navigationList__wrapper {
    z-index: 2;
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: #a6263e;
    top: 0;
    left: -110%;
    transition: all 0.5s;
  }
  .navigationWrapper {
    background-color: #fff;
    z-index: 2;
    opacity: 0.9;
  }
  .hamburgerMenu {
    visibility: visible;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 999999999999;
    top: 35%;
    left: 4%;
    cursor: pointer;
  }
  .imgNavbar {
    margin-left: 163px;
  }
  .navigationList__wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: #a6263e;
    top: 0;
    left: -110%;
    transition: all 0.5s;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    height: 734px;
  }
  .naviagtionList__element {
    color: #fff;
    font-size: 50px;
    justify-content: center;
    font-family: "Gelasio", serif;
    font-weight: bold;
  }
  .navbarList {
    padding-top: 10px;
  }
}

.visible {
  left: 0;
}
@media (max-width: 375px) {
  .navigationList__wrapper {
    z-index: 2;
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: #a6263e;
    top: 0;
    left: -110%;
    transition: all 0.5s;
  }
  .navigationWrapper {
    background-color: #fff;
    z-index: 2;
    opacity: 0.9;
  }
  .hamburgerMenu {
    visibility: visible;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 999999999999;
    top: 40%;
    left: 4%;
    cursor: pointer;
  }
  .imgNavbar {
    margin-left: 120px;
  }
  .navigationList__wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: #a6263e;
    top: 0;
    left: -110%;
    transition: all 0.5s;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    height: 734px;
  }
  .naviagtionList__element {
    color: #fff;
    font-size: 50px;
    justify-content: center;
    font-family: "Gelasio", serif;
    font-weight: bold;
  }
  .navbarList {
    padding-top: 10px;
  }
}
@media (max-width: 360px) {
  .navigationList__wrapper {
    z-index: 2;
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: block;

    top: 0;
    left: -110%;
    transition: all 0.5s;
  }
  .navigationWrapper {
    background-color: #fff;
    z-index: 2;
    opacity: 0.9;
  }
  .hamburgerMenu {
    visibility: visible;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 999999999999;
    top: 40%;
    left: 4%;
    cursor: pointer;
  }
  .imgNavbar {
    margin-left: 120px;
  }
  .navigationList__wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: #a6263e;
    top: 0;
    left: -110%;
    transition: all 0.5s;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    height: 734px;
  }
  .naviagtionList__element {
    color: #fff;
    font-size: 50px;
    justify-content: center;
    font-family: "Gelasio", serif;
    font-weight: bold;
  }
  .navbarList {
    padding-top: 10px;
  }
}
@media (max-width: 320px) {
  .imgNavbar {
    margin-left: 91px;
    margin-top: 15px;
  }
  .navbarList {
    padding-top: 18px;
    margin-left: 40px;
  }
}
@media (max-width: 280px) {
  .imgNavbar {
    margin-left: 56px;
  }
  .navbarList {
    padding-top: 30px;
    margin-left: 38px;
  }
  .navigationList__wrapper {
    z-index: 2;
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: block;

    top: 0;
    left: -110%;
    transition: all 0.5s;
  }
  .navigationWrapper {
    background-color: #fff;
    z-index: 2;
    opacity: 0.9;
  }
  .hamburgerMenu {
    visibility: visible;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 999999999999;
    top: 40%;
    left: 4%;
    cursor: pointer;
  }

  .navigationList__wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: #a6263e;
    top: 0;
    left: -110%;
    transition: all 0.5s;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    height: 734px;
  }
  .naviagtionList__element {
    color: #fff;
    font-size: 50px;
    justify-content: center;
    font-family: "Gelasio", serif;
    font-weight: bold;
  }
}
