.cookie__wrapper {
  position: fixed;
  bottom: 0;
  background-color: #a6263e;
  padding: 18px;
  opacity: 0.9;
  text-align: center;
  z-index: 999999;
}

.text__wrapper {
  color: #fff;
  margin-bottom: 20px;
  font-family: "Montserrat", serif;
}

.accept__btn {
  background-color: #611321;
  width: 110px;
  height: 35px;
  border: none;
  color: #fff;
  text-transform: uppercase;
  padding: 5px;
  font-size: 10px;
  font-weight: bold;
  outline: none;
  border-radius: 14px 14px 14px 14px;
  font-family: "Montserrat", serif;
}
.text__wrapper {
  font-family: "Montserrat", serif;
  font-size: 12px;
}
