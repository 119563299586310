.boxWrapper {
  display: flex;
  justify-content: center;
}
.boxList {
  width: 340px;
  height: 400px;
  list-style: none;
  top: 1320px;
  text-align: center;
  background: transparent;
  border: 2px solid #a6263e;
  box-sizing: border-box;
  border-radius: 10px;
  background: #23242a;
}

.boxLine {
  border: 1px solid #a6263e;
}
.boxParagraphTitle {
  color: rgba(255, 255, 255, 1);
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.boxParagraphContent {
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  text-align: left;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 17px;

  letter-spacing: -0.015em;

  color: #ffffff;
}
// }
@media (max-width: 1200px) {
  .boxParagraphTitle {
    font-size: 12px;
  }
  .boxList {
    width: 274px;
    height: 341px;
  }
  .boxParagraphContent {
    font-size: 10px;
  }
}

@media (max-width: 1200px) {
  .boxList {
    width: 340px;
    height: 400px;
  }
  .boxParagraphTitle {
    font-size: 16px;
  }
  .boxParagraphContent {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .boxList {
  }
}
@media (max-width: 414px) {
  .boxList {
    width: 286px;
    height: 400px;
  }
}

@media (max-width: 375px) {
  .boxList {
    width: 286px;
    height: 400px;
  }
}
@media (max-width: 375px) {
  .boxList {
    width: 193px;
    height: 366px;
  }
  .boxParagraphTitle {
    font-size: 12px;
  }
  .boxParagraphContent {
    font-size: 10px;
    padding-left: 19px;
    padding-right: 21px;
  }
}
