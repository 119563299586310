.claretTitleStyles {
  color: #a6263e;
  font-family: "Gelasio", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  grid-template-columns: 80%;
  margin-left: 209px;
}
.whiteTextStyles {
  color: #fff;
}
.contentWrapper {
}
.greySecondaryText {
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #23242a;
}
@media (max-width: 280px) {
  .claretTitleStyles {
    font-size: 38px;
    margin-left: 153px;
  }
}
