.home_container {
  background-image: url("../../assets/images/line_1.png");
  height: 110vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: 100% 0%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
}

.home_image {
  width: 70%;
  background-image: url("../../assets/images/ZdjGł_MCpro.png");
  background-origin: content-box;

  background-size: 138%;
  background-position: 6% 0%;
  background-repeat: no-repeat;
  height: 1200px;
}
@media (max-width: 768px) {
  .home_container {
    height: auto;
  }
}

.home_content {
  width: 30%;
  padding-top: 400px;
  display: grid;
  justify-content: center;
  grid-template-rows: 13% 23%;
  margin-left: 35px;
}
.titleHome {
  margin-left: 117px;
}
.homeParagraph {
  margin-left: 202px;
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-top: -27px;
  color: #23242a;
  line-height: 25px;
}

.homeParagraphContact {
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  cursor: pointer;
  margin-left: 202px;
  letter-spacing: -0.015em;

  color: #a6263e;
}

@media (max-width: 1440px) {
  .home_container {
    height: 154vh;
  }
}
@media (max-width: 1366px) {
  .home_container {
    background-size: 50%;
  }
}
@media (max-width: 1024px) {
  .home_image {
    width: 100vw;
    background-image: url("../../assets/images/ZdjGł_MCpro.png");
    background-origin: content-box;
    background-size: 207%;
    background-position: 65% 0%;
    background-repeat: no-repeat;
    height: 950px;
    position: absolute;
    background-position: -42% 0%;
    background-size: 132%;
  }
  .home_container {
    background-position: 113% 8%;
    height: 68vh;
    background-size: 67%;
  }
  .home_content {
    margin-left: -14px;
  }
}
@media (max-width: 922px) {
  .home_image {
    width: 100vw;
    background-image: url("../../assets/images/ZdjGł_MCpro.png");
    background-origin: content-box;
    background-size: 207%;
    background-position: 65% 0%;
    background-repeat: no-repeat;
    height: 920px;
    position: absolute;
    background-position: -57% -11%;
    background-size: 126%;
  }
  .home_container {
    background-position: 113% 8%;
    height: 1200px;
    background-size: 67%;
  }
  .home_content {
    width: 75%;
    padding-top: 760px;
  }
}
@media (max-width: 768px) {
  .home_image {
    width: 100vw;
    background-image: url("../../assets/images/ZdjGł_MCpro.png");
    background-origin: content-box;
    background-size: 1354px;
    background-position: -223px -30px;
    background-repeat: no-repeat;
    height: 650px;
    position: absolute;
    height: 880px;
    // z-index: 200;
  }
  .home_content {
    width: 74%;
    padding-top: 891px;
    margin-left: 0px;
    text-align: center;

    padding-right: 20px;
  }
  .homeParagraph {
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .titleHome {
    margin-left: 0px;
  }
  .homeParagraphContact {
    margin-top: 9px;
    margin-bottom: 97px;
    text-align: center;
  }
  .home_container {
    background-position: 101% 8%;
    height: 1329px;
  }
}

@media (max-width: 575px) {
  .home_container {
    height: 1486px;
  }
}

@media (max-width: 540px) {
  .home_image {
    width: 100vw;
    background-image: url("../../assets/images/ZdjGł_MCpro.png");
    background-origin: content-box;
    background-size: 930px;
    background-position: -220px -30px;
    background-repeat: no-repeat;
    height: 650px;
    position: absolute;
    // z-index: 200;
  }
  .home_content {
    width: 52%;
    padding-top: 645px;
    margin-left: 0px;

    padding-left: 25px;
    padding-right: 25px;
  }
  .homeParagraph {
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .titleHome {
    margin-left: 0px;
  }
  .homeParagraphContact {
    margin-top: 9px;
    margin-bottom: 97px;
    text-align: center;
  }
}
@media (max-width: 424px) {
  .home_image {
    width: 100vw;
    background-image: url("../../assets/images/ZdjGł_MCpro.png");
    background-origin: content-box;
    background-size: 930px;
    background-position: -220px -30px;
    background-repeat: no-repeat;
    height: 650px;
    position: absolute;
    // z-index: 200;
  }
  .home_content {
    width: 52%;
    padding-top: 598px;
    margin-left: 0px;

    padding-left: 25px;
    padding-right: 25px;
  }
  .homeParagraph {
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .titleHome {
    margin-left: 0px;
  }
  .homeParagraphContact {
    margin-top: 9px;
    margin-bottom: 97px;
    text-align: center;
  }
  .home_container {
    height: 154vh;
  }
}
@media (max-width: 375px) {
  .home_image {
    width: 100vw;
    background-image: url("../../assets/images/ZdjGł_MCpro.png");
    background-origin: content-box;
    background-size: 930px;
    background-position: -215px -35px;
    background-repeat: no-repeat;
    height: 650px;
    position: absolute;
    // z-index: 200;
  }
  .home_content {
    padding-top: 598px;
    margin-left: 0px;
    display: grid;
    justify-content: center;
    width: 44%;
    padding-left: 25px;
    padding-right: 25px;
  }
  .homeParagraph {
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .titleHome {
    margin-left: 0px;
  }
  .homeParagraphContact {
    margin-top: 9px;
    margin-bottom: 97px;
    text-align: center;
  }
}
@media (max-width: 360px) {
  .home_container {
    background-position: 100% 12%;
  }
  .home_content {
    padding-top: 581px;
  }
  .homeParagraph {
    margin-top: -23px;
    margin-bottom: 12px;
  }
}
@media (max-width: 320px) {
  .home_container {
    height: 201vh;
  }
  .home_content {
    padding-left: 0px;
  }
}
@media (max-width: 280px) {
  .home_container {
    background-position: 100% 12%;
    height: 169vh;
  }
  .home_content {
    padding-top: 581px;
  }
  .homeParagraph {
    width: 64%;
    margin-left: 139px;
  }

  .homeParagraphContact {
    width: 40%;
    text-align: center;
    width: 37%;
    text-align: center;
    margin-left: 190px;
  }
}
